import ProductGrid from "~/components/ProductGrid";
import { getCommonSliceProps, getRichText, resolveMedia } from "~/external/prismic";
import connectSlice from "~/lib/connectSlice";

export function mapSliceToProps(slice, externalData) {
  const { primary, items = [] } = slice;

  // console.log('SLICE', slice.primary.sound_profile); // .data

  const { products = [], configurations = [], categories = [] } = externalData;
  // console.log('externalData', externalData);

  // console.log(slice);
  const prs = items.map((i) => i.product);
  // console.log(prs);

  let category = null;

  // check if category has data...
  if (primary.category.hasOwnProperty("data")) {
    // console.log('CAT', primary.category);
    const categoryMatch = categories.find((c) => c.key === primary.category.data.key);
    if (categoryMatch) {
      category = categoryMatch;
    }
  }

  let cards = [];
  if (category) {
    // either get carts from category
    const categoryProducts = category.products;
    categoryProducts.sort((a, b) => (a.key > b.key ? 1 : -1));
    cards = categoryProducts.map((pr) => ({
      product: pr,
    }));
  } else {
    // or from the items (repeatable field...)
    cards = items.map((item) => {
      // console.log(item);

      const obj = {
        media: resolveMedia(item, "1:1"),
        title: item.title,
        subtitle: item.subtitle,
        text: getRichText(item.text),
        // product: product,
        variantKey: item.variant_key && item.variant_key,
        buttonText: item.button_text,
      };

      if (item.sound_profile) {
        if (item.sound_profile.data) {
          obj.soundProfile = item.sound_profile.data;
        }
      }

      if (item.product.type === "product") {
        obj.product = products.find((pr) => pr.key === item.product.data.key);
      } else if (item.product.type === "variant") {
        // find the configuration that matches the uid!
        const configuration = configurations.find((c) => c.uid === item.product.uid);
        if (configuration) {
          // console.log(configuration);
          obj.product = configuration;
          // set title
          obj.title = configuration.data.product_title;
          // set descriotion
          obj.text = getRichText(configuration.data.description);
          // media
          obj.media = [
            configuration.data.image.url,
            "image",
            configuration.data.image.width,
            configuration.data.image.height,
          ];
        }
      }

      return obj;
    });
  }

  const props = {
    ...getCommonSliceProps(slice),

    // ctpCategoryKey: primary.ctp_category_key,
    type: primary.card_type && primary.card_type.charAt(0).toLowerCase(), // getType(primary.card_type), // primary.card_type === 'A' ? 'a' : 'b', // card_type
    buttonType: primary.button_type && primary.button_type.toLowerCase(),
    buttonAction: primary.button_action && primary.button_action.toLowerCase(),
    columnsOnDesktop: primary.columns_on_desktop || 4,
    columnsOnMobile: primary.columns_on_mobile || 1,

    cards, // resolved above...
  };
  return props;
}

export default connectSlice(mapSliceToProps)(ProductGrid);
